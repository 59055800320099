/**
 * Envoy webapp configuration
 */

const appId = `${process.env.npm_package_name}@${process.env.npm_package_version}`
const isDev = process.env.REACT_APP_ENV !== 'production'
const isDebug = !!process.env.NODE_DEBUG

export default {
  env: process.env.NODE_ENV,
  name: process.env.npm_package_name,
  version: process.env.npm_package_version,
  appUrl: process.env.APP_URL,
  appId,
  isDev,
  isDebug,

  sendgrid: {
    apiKey:
      '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'
  },
  workbook: {
    sheetName: 'Customers'
  }
}
